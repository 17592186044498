import { ComponentPropsWithoutRef } from 'react'
import { Link } from 'react-router-dom'

import backgroundImage from '../../assets/img/river-6175173.jpg'
import Card from '../Card/Card'
import Logo from '../Logo'
import Toaster from '../ToastProvider'

interface ExternalPageProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  subtitle?: string | React.ReactNode
}

const ExternalPage = ({ children, title, subtitle }: ExternalPageProps) => {
  return (
    <>
      <div className="relative min-h-screen">
        <div
          className="absolute inset-0 sm:inset-6 bg-cover bg-center bg-no-repeat sm:rounded-xl"
          style={{ backgroundImage: `url(${backgroundImage})` }}
          aria-hidden="true"
        />
        <div className="relative mx-auto flex min-h-screen max-w-4xl px-6 sm:px-12 flex-col items-center lg:items-start justify-center py-6 sm:py-20">
          <Link to="/">
            <Logo variant="white" className="h-10 w-auto mb-8" />
          </Link>
          <div className="w-full max-w-md grow flex flex-col justify-center">
            <Card variant="white" spacing="lg">
              <div className="flex flex-col gap-2">
                <span className="text-5xl font-medium leading-tight tracking-tight text-brand-black">
                  {title}
                </span>
                {subtitle && (
                  <span className="text-sm font-normal text-brand-black">
                    {subtitle}
                  </span>
                )}
              </div>
              <div className="mt-8">{children}</div>
            </Card>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  )
}

export default ExternalPage
