import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ExternalPage from '../components/ExternalPage/ExternalPage'
import Button from '../components/core/Button/Button'
import { Form, FormField, FormFieldGrid } from '../components/core/Form/Form'
import Input from '../components/core/Input/Input'
import { resetPassword } from '../loaders/user'

const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [email, setEmail] = useState('')

  const { mutate: resetPasswordMutation } = useMutation({
    mutationFn: ({ email }: { email: string }) => resetPassword({ email }),
    onSuccess: () => {
      toast.success(t('forgotPassword.notification.success'))
      navigate('/login')
    },
    onError: (error: Error) => {
      toast.error(
        t('forgotPassword.notification.error', { error: error.toString() })
      )
    },
  })

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault()
        resetPasswordMutation({ email })
      }}
    >
      <FormFieldGrid>
        <FormField columns={6}>
          <Input
            variant="solid"
            id="email"
            name="email"
            type="email"
            placeholder={t('forgotPassword.form.email')}
            autoComplete="email"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </FormField>
      </FormFieldGrid>

      <Button type="submit" variant="primary" className="uppercase" isFull>
        {t('forgotPassword.form.submit')}
      </Button>
    </Form>
  )
}

const ForgotPassword = () => {
  const { t } = useTranslation()

  return (
    <ExternalPage title={t('forgotPassword.title')}>
      <ForgotPasswordForm />
    </ExternalPage>
  )
}
export default ForgotPassword
