import _ from 'lodash'
import moment, { MomentInput } from 'moment'
import 'moment/locale/da'

type IntervalType = 'month' | 'quarter'

interface IntervalFormatOptions {
  locale?: string
  monthFormat?: string
  yearFormat?: string
}

export const formatInterval = (
  year: number,
  interval: number,
  byInterval: IntervalType,
  {
    locale = navigator.language,
    monthFormat = 'MMM',
    yearFormat = 'YY',
  }: IntervalFormatOptions = {}
) => {
  const m = moment.utc()

  m.locale(locale)
  m.year(year)

  if (byInterval === 'month') {
    m.month(interval - 1) // month() indexes from 0
    return `${m.format(monthFormat)} ${m.format(yearFormat)}`
  } else if (byInterval === 'quarter') {
    m.quarter(interval)
    return `${m.format('[Q]Q')} ’${m.format(yearFormat)}`
  }

  return `${year}-${interval}`
}

interface DateFormatOptions {
  format?: string
  defaultValue?: string
  locale?: string
}

export const formatDate = (
  date: MomentInput,
  {
    format = 'LL',
    defaultValue = '-',
    locale = navigator.language,
  }: DateFormatOptions = {}
) => {
  if (!date) {
    return defaultValue
  }

  const m = moment(date)
  m.locale(locale)

  return m.format(format)
}

export interface FormatNumberOptions {
  signDisplay?: 'auto' | 'always' | 'exceptZero' | 'never'
  currency?: string
  decimals?: number
  locale?: string | null
}

export const formatNumber = (
  number: number | null,
  { signDisplay, currency, decimals = 2, locale }: FormatNumberOptions = {}
) => {
  if (number === null) {
    return number
  }

  if (!locale) {
    locale = navigator.language
  }

  let currencyOptions = {}
  if (currency) {
    currencyOptions = {
      style: 'currency',
      currencyDisplay: 'narrowSymbol',
      currency,
    }
  }

  return new Intl.NumberFormat(locale, {
    signDisplay,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    // @ts-expect-error not exposed in Intl.NumberFormat types
    trailingZeroDisplay: 'stripIfInteger',
    ...currencyOptions,
  }).format(number)
}

export const getGridValueFormatter = (
  field: string,
  options: FormatNumberOptions = {}
) => {
  return function (params: Record<string, unknown>) {
    const value = _.get(params, ['data', field])

    return value !== undefined ? formatNumber(value, options) : ''
  }
}

const formatIntervalFromDate = (
  date: MomentInput,
  byInterval: 'month' | 'quarter',
  {
    locale = navigator.language,
    monthFormat = 'MMM',
  }: IntervalFormatOptions = {}
) => {
  const m = moment.utc(date)

  m.locale(locale)

  if (byInterval === 'month') {
    return m.format(monthFormat)
  } else if (byInterval === 'quarter') {
    return `Q${m.format('Q')}`
  }
}

export const formatIntervalRange = (
  fromDate: MomentInput,
  toDate: MomentInput,
  interval: IntervalType,
  {
    locale = navigator.language,
    monthFormat = 'MMMM',
    yearFormat = 'YYYY',
  }: IntervalFormatOptions = {}
) => {
  const formattedFromYear = moment.utc(fromDate).format(yearFormat)
  const formattedToYear = moment.utc(toDate).format(yearFormat)

  const formattedFromInterval = formatIntervalFromDate(fromDate, interval, {
    locale,
    monthFormat,
  })
  const formattedToInterval = formatIntervalFromDate(toDate, interval, {
    locale,
    monthFormat,
  })

  if (formattedFromYear === formattedToYear) {
    if (formattedFromInterval === formattedToInterval) {
      return `${formattedFromInterval} ${formattedFromYear}`
    } else {
      return `${formattedFromInterval} - ${formattedToInterval} ${formattedFromYear}`
    }
  } else {
    return `${formattedFromInterval} ${formattedFromYear} - ${formattedToInterval} ${formattedToYear}`
  }
}
