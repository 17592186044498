import {
  AdjustmentsHorizontalIcon,
  BuildingStorefrontIcon,
  PresentationChartBarIcon,
  Squares2X2Icon,
  UsersIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'

export interface NavItem {
  label: string
  href: string
  right?: string
  icon?: React.ElementType
  countLoader?: () => Promise<number>
}

export interface NavGroupItem extends NavItem {
  scope: string
  children: NavItem[]
}

const navigation: (NavItem | NavGroupItem)[] = [
  {
    label: 'nav.overview',
    href: '/',
    right: 'result:read',
    icon: Squares2X2Icon,
  },
  {
    label: 'nav.review',
    href: '/review',
    right: 'result:read',
    icon: PresentationChartBarIcon,
  },
  {
    label: 'nav.vendors',
    href: '/vendors/*',
    scope: '/vendors/*',
    icon: BuildingStorefrontIcon,
    children: [
      {
        label: 'nav.vendors',
        href: '/vendors',
        right: 'vendor:read',
      },
      {
        label: 'nav.invoices',
        href: '/vendors/invoices',
        right: 'vendor:read',
      },
    ],
  },
  {
    label: 'nav.tenants',
    href: '/tenants',
    right: 'tenant:read',
    icon: UsersIcon,
  },
  {
    label: 'nav.setup',
    href: '/setup/buildings',
    scope: '/setup/*',
    icon: WrenchScrewdriverIcon,
    children: [
      {
        label: 'nav.buildings',
        href: '/setup/buildings',
        right: 'building:write',
      },
      {
        label: 'nav.accountCharts',
        href: '/setup/account_charts',
        right: 'account_chart:write',
      },
      {
        label: 'nav.records',
        href: '/setup/records',
        right: 'record:write',
      },
      {
        label: 'nav.internalBenchmarks',
        href: '/setup/internal_benchmarks',
        right: 'internal_benchmark:write',
      },
    ],
  },
  {
    label: 'nav.admin',
    href: '/admin/organization',
    scope: '/admin/*',
    icon: AdjustmentsHorizontalIcon,

    children: [
      {
        label: 'nav.organization',
        href: '/admin/organization',
        right: 'org:admin',
      },
      {
        label: 'nav.users',
        href: '/admin/users',
        right: 'user:admin',
      },
      {
        label: 'nav.apps',
        href: '/admin/apps',
        right: 'app:admin',
      },
    ],
  },
]

export default navigation
