import { useQuery } from '@tanstack/react-query'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import Table from '../components/Table/Table'
import { TenantDetail } from '../components/TenantDetail/TenantDetail'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'
import FormattedNumber from '../components/core/FormattedNumber/FormattedNumber'
import { getTenants } from '../loaders/tenants'
import { Tenant } from '../models'

const PAGE_SIZE = 20

const formatDate = (date: string) => (date ? moment(date).format('LL') : '-')

const columnHelper = createColumnHelper<Tenant>()

const Tenants = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation(['dashboard'])
  const { showDrawer } = useDrawer()

  const initialPage = searchParams.get('page')
  const [page, setPage] = useState(initialPage ? Number(initialPage) : 1)

  const { data, isLoading } = useQuery({
    queryKey: ['tenants', page],
    queryFn: () => getTenants({ page, pageSize: PAGE_SIZE }),
  })

  const tenants = useMemo(() => data?.data ?? [], [data])
  const pagination = useMemo(
    () =>
      data?.pagination ?? {
        page,
        pageSize: PAGE_SIZE,
        pageCount: 0,
        total: 0,
        previousPage: null,
        nextPage: null,
      },
    [data]
  )
  const showTenant = useCallback(
    (tenant: Tenant) => {
      showDrawer(tenant.name, <TenantDetail tenant={tenant} />)
    },
    [showDrawer]
  )

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          header: t('tenants.column.name'),
        }),
        columnHelper.accessor('building.buildingReference', {
          header: t('tenants.column.building'),
        }),
        columnHelper.accessor((tenant) => tenant.agreements[0]?.startDate, {
          header: t('tenants.column.startDate'),
          cell: (ctx) => formatDate(ctx.getValue()),
        }),
        columnHelper.accessor((tenant) => tenant.agreements[0]?.rentAmount, {
          header: t('tenants.column.rentAmount'),
          cell: (ctx) => (
            <FormattedNumber value={ctx.getValue()} isCurrency={true} />
          ),
        }),
      ] as ColumnDef<Tenant>[],
    [t]
  )
  return (
    <Checked right="tenant:read">
      <Page>
        <Header subtitle={t('tenants.subtitle') ?? ''}>
          {t('tenants.title')}
        </Header>
        <Section>
          <Card spacing="none">
            <Table
              isLoading={isLoading}
              emptyText={t('tenants.empty')}
              columns={columns}
              data={tenants}
              pagination={pagination}
              onPageChange={(page) => setPage(page)}
              onRowClick={(tenant) => showTenant(tenant)}
            />
          </Card>
        </Section>
      </Page>
    </Checked>
  )
}

export default Tenants
