import { MutationFunction, useMutation } from '@tanstack/react-query'
import { FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

interface ApiCallbackOptions {
  successKey?: string
  errorKey?: string
  tNamespace?: string | string[]
}

export const useFetchCallback = <TData>(
  loader: () => Promise<TData>,
  dependencies: unknown[],
  { successKey, errorKey, tNamespace = 'dashboard' }: ApiCallbackOptions = {}
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<TData | null>(null)

  const { t } = useTranslation(tNamespace)

  const fetch = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await loader()
      setData(data)

      if (successKey) {
        toast.success(t(successKey))
      }
    } catch (error) {
      if (errorKey) {
        toast.error(t(errorKey, { error: (error as Error).toString() }))
      }
    } finally {
      setIsLoading(false)
    }
  }, dependencies)

  return { isLoading, data, fetch }
}

export const useMutationCallback = <TData, TVariables>(
  loader: MutationFunction<TData, TVariables>,
  data: TVariables,
  { successKey, errorKey, tNamespace = 'dashboard' }: ApiCallbackOptions = {}
) => {
  const { t } = useTranslation(tNamespace)
  const { mutate } = useMutation(loader)

  return useCallback(async () => {
    mutate(data, {
      onSuccess: () => {
        if (successKey) {
          toast.success(t(successKey))
        }
      },
      onError: (error: unknown) => {
        if (errorKey) {
          toast.error(
            t(errorKey, {
              error: (error as Error).toString(),
            })
          )
        }
      },
    })
  }, [data])
}

export const useFormSubmitCallback = <TData, TVariables>(
  loader: MutationFunction<TData, TVariables>,
  data: TVariables,
  { successKey, errorKey, tNamespace = 'dashboard' }: ApiCallbackOptions = {}
) => {
  const { t } = useTranslation(tNamespace)
  const saveMutation = useMutation(loader)

  return useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      saveMutation.mutate(data, {
        onSuccess: () => {
          if (successKey) {
            toast.success(t(successKey))
          }
        },
        onError: (error: unknown) => {
          if (errorKey) {
            toast.error(
              t(errorKey, {
                error: (error as Error).toString(),
              })
            )
          }
        },
      })
    },
    [data]
  )
}
