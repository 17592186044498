import classNames from 'classnames'
import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

const variants = {
  default: 'bg-white ring-1 ring-gray-200 rounded-lg',
  white: 'bg-white rounded-2xl',
}

const spacings = {
  default: 'px-4 py-5 sm:p-6',
  none: 'px-0 py-0 sm:p-0',
  lg: 'p-6 sm:p-12',
}

interface CardProps extends ComponentPropsWithoutRef<'div'> {
  as?: React.ElementType
  className?: string
  variant?: keyof typeof variants
  spacing?: keyof typeof spacings
}

const Card = ({
  as: Component = 'div',
  children,
  className,
  spacing = 'default',
  variant = 'default',
  ...props
}: CardProps) => {
  return (
    <Component
      className={classNames('overflow-hidden', variants[variant])}
      {...props}
    >
      <div className={twMerge(spacings[spacing], className)}>{children}</div>
    </Component>
  )
}

export default Card
