import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useBuildingFilters } from '../../components/BuildingPopover/BuildingFilterProvider'
import { useTimeRangeFilters } from '../../components/TimeRangePopover/TimeRangeFilterProvider'
import AgGrid, {
  defaultGridOptions,
  defaultTheme,
} from '../../components/core/AgGrid/AgGrid'
import { useNumberColumn } from '../../components/core/AgGrid/utils'
import { getPnl } from '../../loaders/pnl'
import { PnlRow } from '../../models'
import CategoryCellRenderer from './CategoryCellRenderer'
import InvoicedCellRenderer from './InvoicedCellRenderer'

const PnlTable = () => {
  const { t } = useTranslation(['dashboard', 'categories'])
  const { building } = useBuildingFilters()
  const { fromDate, toDate } = useTimeRangeFilters()

  const { data: rowData } = useQuery({
    queryKey: ['pnl', building?.id, fromDate, toDate],
    queryFn: () =>
      getPnl(building!.id, {
        from: fromDate,
        to: toDate,
      }),
    enabled: !!building?.id,
  })

  const getDataPath = useCallback(
    (row: PnlRow) => {
      // Translate the standard name
      const path = row.parentCategories.map((parentCategory) =>
        t(`${parentCategory.standard}.shortName`, { ns: 'categories' })
      )

      // If the category has child categories, add an 'Other' group
      if (row.childCategories.length > 0) {
        path.push(t('review.otherRow'))
      }

      return path
    },
    [rowData]
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      ...defaultGridOptions,
      // Show all groups in the first column
      groupDisplayType: 'singleColumn',

      // Show the grand total row at the bottom
      grandTotalRow: 'bottom',

      getRowStyle: (params) => {
        // Style the grand total row
        if (params.node.footer) {
          return { fontWeight: 600 }
        }
      },

      autoGroupColumnDef: {
        headerName: t('review.column.category'),
        headerClass: 'bg-white font-semibold',
        cellClass: 'bg-brand-bg',
        cellRenderer: CategoryCellRenderer,
        cellRendererParams: {
          suppressCount: true,
        },
      },
    }),
    []
  )

  const context = useMemo(
    () => ({
      building,
      fromDate,
      toDate,
    }),
    [building, fromDate, toDate]
  )

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    useNumberColumn({
      field: 'invoiced',
      headerName: t('review.column.invoiced'),
      cellRenderer: InvoicedCellRenderer,
      aggFunc: 'sum',
    }),
    useNumberColumn({
      field: 'budget',
      headerName: t('review.column.budget'),
      aggFunc: 'sum',
    }),
    useNumberColumn({
      field: 'unexpected',
      headerName: t('review.column.unexpected'),
      aggFunc: 'sum',
      cellStyle: (params) => {
        return params.value > 0 ? { color: 'red' } : null
      },
    }),
  ])

  const defaultColDef: ColDef = {
    flex: 1,
  }

  return (
    <AgGrid
      theme={defaultTheme}
      domLayout="autoHeight"
      rowData={rowData}
      columnDefs={colDefs}
      defaultColDef={defaultColDef}
      gridOptions={gridOptions}
      treeData={true}
      getDataPath={getDataPath}
      context={context}
    />
  )
}

export default PnlTable
