import { useTranslation } from 'react-i18next'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import ExternalPage from '../components/ExternalPage/ExternalPage'
import LoginForm from '../components/LoginForm/LoginForm'
import Toaster from '../components/ToastProvider'

const LoginPage = () => {
  const { t } = useTranslation(['login'])

  return (
    <>
      <ExternalPage
        title={t('title')}
        subtitle={
          <Trans
            i18nKey="subtitle"
            ns="login"
            components={{
              contactLink: (
                <a
                  href="mailto:support@plinto.ai"
                  className="inline text-brand-black underline hover:no-underline"
                />
              ),
            }}
          />
        }
      >
        <LoginForm />
        <div className="mt-4">
          <Link
            to="/password/forgot"
            className="text-sm text-brand-neutral-2 underline hover:no-underline"
          >
            {t('form.forgotPassword')}
          </Link>
        </div>
      </ExternalPage>
      <Toaster />
    </>
  )
}

export default LoginPage
