import { User } from '../models'
import httpClient from '../utils/httpClient'

export const NUMBER_LOCALES = ['en-US'] as const

export type NumberLocale = (typeof NUMBER_LOCALES)[number]

export interface UserSettings {
  numberLocale?: NumberLocale | null
  notifications?: {
    invoiceAnalysis?: boolean
  }
}

export const getUsers = async () => {
  const response = await httpClient.get(`/user`)

  return response.data
}

export const createUser = async ({
  firstName,
  lastName,
  email,
  role,
}: Partial<User>) => {
  const response = await httpClient.post(`/user`, {
    firstName,
    lastName,
    email,
    role,
  })

  return response.data
}

export const getUser = async (userId: number) => {
  const response = await httpClient.get(`/user/${userId}`)

  return response.data
}

export const updateUser = async (
  userId: number,
  { firstName, lastName, role }: Partial<User>
) => {
  const response = await httpClient.patch(`/user/${userId}`, {
    firstName,
    lastName,
    role,
  })

  return response.data
}

export const deleteUser = async (userId: number) => {
  const response = await httpClient.delete(`/user/${userId}`)

  return response.data
}

export const loginUser = async ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  const response = await httpClient.post(`/user/login`, {
    email,
    password,
  })

  return response.data
}

export const confirmUser = async ({
  token,
  password,
}: {
  token: string
  password: string
}) => {
  await httpClient.post(`/user/confirm`, {
    token,
    password,
  })
}

export const resetPassword = async ({ email }: { email: string }) => {
  await httpClient.post(`/user/password/reset`, {
    email,
  })
}

export const setPassword = async ({
  token,
  password,
}: {
  token: string
  password: string
}) => {
  await httpClient.post(`/user/password`, {
    token,
    password,
  })
}

export const getSettings = async () => {
  const response = await httpClient.get<UserSettings>(`/user/settings`)

  return response.data
}

export const updateSettings = async (settings: Partial<UserSettings>) => {
  const response = await httpClient.patch(`/user/settings`, settings)

  return response.data
}
