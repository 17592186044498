import Logo from '../../Logo'

const NavLogo = () => {
  return (
    <div className="flex flex-shrink-0 items-center">
      <Logo className="block h-5 w-auto lg:hidden" />
      <Logo className="hidden h-5 w-auto lg:block" />
    </div>
  )
}

export default NavLogo
