import { Navigate, Outlet, useLocation } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  if (isAuthenticated) {
    return <Outlet />
  }

  // Append the requested path to the login page, to allow redirecting
  // back to the it after login
  let path = '/login'

  if (location.pathname !== '/') {
    let redirect = location.pathname

    // If the redirect path has a query string, append it to the path
    if (location.search) {
      redirect += location.search
    }

    path += `?redirect=${encodeURIComponent(redirect)}`
  }

  return <Navigate to={path} />
}

export default ProtectedRoute
