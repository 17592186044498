import { ComponentPropsWithoutRef } from 'react'

import logoBlack from '../assets/img/logo_black.svg'
import logoWhite from '../assets/img/logo_white.svg'

interface LogoProps extends ComponentPropsWithoutRef<'img'> {
  variant?: 'black' | 'white'
}

const Logo = ({ variant = 'black', ...props }: LogoProps) => {
  return (
    <img
      src={variant === 'black' ? logoBlack : logoWhite}
      alt="Plinto"
      {...props}
    />
  )
}

export default Logo
