import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getVendor } from '../../loaders/vendors'
import { Vendor } from '../../models'
import { formatDate } from '../../utils/format'
import {
  Form,
  FormField,
  FormFieldGrid,
  FormLabel,
  FormSection,
  FormValue,
} from '../core/Form/Form'
import FormattedNumber from '../core/FormattedNumber/FormattedNumber'
import AgreementList from './AgrementList'
import InvoiceList from './InvoiceList'

interface VendorDetailProps {
  vendor: Vendor
}

export const VendorDetail = ({ vendor: initialVendor }: VendorDetailProps) => {
  const { t } = useTranslation(['dashboard'])
  const queryClient = useQueryClient()

  // Load the vendor
  const { data: vendor } = useQuery({
    queryKey: ['vendor', initialVendor.id],
    queryFn: () => getVendor(initialVendor.id),
    initialData: initialVendor,
  })

  // Refetch the vendor if any of the documents change
  const onDocumentChange = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ['vendor', initialVendor.id],
    })
    queryClient.invalidateQueries({ queryKey: ['vendors'] })
  }, [queryClient])

  // FIXME: Return from API
  const currentAgreement = useMemo(() => vendor?.agreements[0], [vendor])

  return (
    <Form>
      <FormSection
        title={t('vendorDetail.general')}
        subtitle={t('vendorDetail.generalDescription')}
      >
        <FormFieldGrid>
          <FormField>
            <FormLabel>{t('vendorDetail.startDate')}</FormLabel>
            <FormValue>{formatDate(currentAgreement?.startDate)}</FormValue>
          </FormField>
          <FormField>
            <FormLabel>{t('vendorDetail.endDate')}</FormLabel>
            <FormValue>{formatDate(currentAgreement?.endDate)}</FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('vendorDetail.recurringCost')}</FormLabel>
            <FormValue>
              <FormattedNumber
                value={currentAgreement?.recurringCost}
                isCurrency={true}
              />
            </FormValue>
          </FormField>
          <FormField>
            <FormLabel>{t('vendorDetail.recurringCostFrequency')}</FormLabel>
            <FormValue>
              {currentAgreement?.recurringCostFrequency
                ? t(
                    `recurringCostFrequency.${currentAgreement?.recurringCostFrequency}`
                  )
                : '-'}
            </FormValue>
          </FormField>

          <FormField>
            <FormLabel>{t('vendorDetail.oneTimeCost')}</FormLabel>
            <FormValue>
              <FormattedNumber
                value={currentAgreement?.oneTimeCost}
                isCurrency={true}
              />
            </FormValue>
          </FormField>
          <FormField>
            <FormLabel>{t('vendorDetail.building')}</FormLabel>
            <FormValue>{vendor?.building.buildingReference}</FormValue>
          </FormField>
        </FormFieldGrid>
      </FormSection>
      <FormSection
        title={t('vendorDetail.agreements')}
        subtitle={t('vendorDetail.agreementsDescription')}
      >
        {vendor && (
          <AgreementList vendor={vendor} onChange={onDocumentChange} />
        )}
      </FormSection>

      <FormSection
        title={t('vendorDetail.invoices')}
        subtitle={t('vendorDetail.invoicesDescription')}
      >
        {vendor && (
          <InvoiceList vendor={vendor} onChange={onDocumentChange} count={10} />
        )}
      </FormSection>
    </Form>
  )
}
