import { User } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import Table from '../components/Table/Table'
import Button from '../components/core/Button/Button'
import { getUsers } from '../loaders/user'

const columnHelper = createColumnHelper<User>()

export default function Users() {
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()

  const { data: users, isLoading } = useQuery(['users'], getUsers)

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('firstName', {
          header: t('users.column.firstName'),
        }),
        columnHelper.accessor('lastName', {
          header: t('users.column.lastName'),
        }),
        columnHelper.accessor('email', {
          header: t('users.column.email'),
        }),
        columnHelper.accessor('role', {
          header: t('users.column.role'),
        }),
        columnHelper.display({
          id: 'actions',
          header: () => (
            <span className="sr-only">{t('users.column.actions')}</span>
          ),
          cell: (ctx) => {
            const user = ctx.row.original
            return (
              <Button
                key="edit"
                variant="neutral"
                onClick={() => navigate(`/admin/users/${user.userId}/edit`)}
              >
                {t('users.button.edit')}
              </Button>
            )
          },
          meta: {
            cellClassName: 'text-right',
          },
        }),
      ] as ColumnDef<User>[],
    []
  )

  return (
    <Checked right="user:admin">
      <Page>
        <Header
          subtitle={t('users.subtitle') ?? ''}
          actions={
            <>
              <Button
                as={Link}
                onClick={() => navigate('/admin/users/new')}
                variant="primary"
                className="ml-2"
              >
                {t('users.createUser')}
              </Button>
            </>
          }
        >
          {t('users.title')}
        </Header>
        <Section id="grid">
          <Card spacing="none">
            <Table isLoading={isLoading} columns={columns} data={users} />
          </Card>
        </Section>
      </Page>
    </Checked>
  )
}
