import NavLogo from '../NavLogo/NavLogo'
import ProfileMenu from '../ProfileMenu'
import navigation, { NavGroupItem, NavItem } from '../navigation'
import { SidebarGroupItem, SidebarItem } from './SidebarItem'

export default function Sidebar() {
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
      <div className="flex h-16 shrink-0 items-center">
        <NavLogo />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.label}>
                  {'children' in item ? (
                    <SidebarGroupItem item={item as NavGroupItem} />
                  ) : (
                    <SidebarItem item={item as NavItem} />
                  )}
                </li>
              ))}
            </ul>
          </li>
          <li className="-mx-6 mt-auto">
            <ProfileMenu />
          </li>
        </ul>
      </nav>
    </div>
  )
}
