import { useQuery } from '@tanstack/react-query'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import Card from '../components/Card/Card'
import Checked from '../components/Checked'
import { Header, Page, Section } from '../components/Page/Page'
import Table from '../components/Table/Table'
import { VendorDetail } from '../components/VendorDetail/VendorDetail'
import Button from '../components/core/Button/Button'
import { useDrawer } from '../components/core/Drawer/DrawerProvider'
import FormattedNumber from '../components/core/FormattedNumber/FormattedNumber'
import { getVendor, getVendors } from '../loaders/vendors'
import { Vendor } from '../models'
import { formatDate } from '../utils/format'

const PAGE_SIZE = 20

const columnHelper = createColumnHelper<Vendor>()

const Vendors = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const { showDrawer } = useDrawer()

  const initialPage = searchParams.get('page')
  const [page, setPage] = useState(initialPage ? Number(initialPage) : 1)

  const showVendor = useCallback((vendor: Vendor) => {
    showDrawer(vendor.name, <VendorDetail vendor={vendor} />)
  }, [])

  // Load vendors
  const { data, isLoading } = useQuery({
    queryKey: ['vendors', page],
    queryFn: () => getVendors({ page, pageSize: PAGE_SIZE }),
  })

  const vendors = useMemo(() => data?.data ?? [], [data])
  const pagination = useMemo(
    () =>
      data?.pagination ?? {
        page,
        pageSize: PAGE_SIZE,
        pageCount: 0,
        total: 0,
        previousPage: null,
        nextPage: null,
      },
    [data]
  )
  // Load initial vendor from search param if present
  const initialVendorId = searchParams.has('vendorId')
    ? parseInt(searchParams.get('vendorId')!)
    : null

  const { data: initialVendor } = useQuery({
    enabled: !!initialVendorId,
    queryKey: ['vendor', initialVendorId],
    queryFn: () => getVendor(initialVendorId!),
    onSuccess: (vendor) => {
      showVendor(vendor)
    },
    onError: () => {
      setSearchParams({})
    },
  })

  useEffect(() => {
    if (initialVendor) {
      showVendor(initialVendor)
    }
  }, [initialVendor])

  const columns = useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          header: t('vendors.column.name'),
        }),
        columnHelper.accessor('building.buildingReference', {
          header: t('vendors.column.building'),
        }),
        columnHelper.accessor((vendor) => vendor.agreements[0]?.recurringCost, {
          header: t('vendors.column.recurringCost'),
          cell: (ctx) => {
            return <FormattedNumber value={ctx.getValue()} isCurrency={true} />
          },
        }),
        columnHelper.accessor(
          (vendor) => vendor.agreements[0]?.recurringCostFrequency,
          {
            header: t('vendors.column.recurringCostFrequency'),
            cell: (ctx) => {
              const value = ctx.getValue()
              return value ? t(`recurringCostFrequency.${value}`) : '-'
            },
          }
        ),
        columnHelper.accessor((vendor) => vendor.agreements[0]?.startDate, {
          header: t('vendors.column.startDate'),
          cell: (ctx) => formatDate(ctx.getValue()),
        }),
        columnHelper.accessor((vendor) => vendor.agreements[0]?.endDate, {
          header: t('vendors.column.endDate'),
          cell: (ctx) => formatDate(ctx.getValue()),
        }),
        columnHelper.display({
          id: 'actions',
          header: () => (
            <span className="sr-only">{t('vendors.column.actions')}</span>
          ),
          cell: (ctx) => {
            const vendor = ctx.row.original
            return (
              <Button
                key="edit"
                variant="neutral"
                onClick={() => navigate(`/vendors/${vendor.id}/edit`)}
              >
                {t('vendors.button.edit')}
              </Button>
            )
          },
          meta: {
            cellClassName: 'text-right',
          },
        }),
      ] as ColumnDef<Vendor>[],
    [vendors]
  )

  return (
    <Checked right="vendor:read">
      <Page>
        <Header
          subtitle={t('vendors.subtitle') ?? ''}
          actions={
            <>
              <Button variant="primary" className="ml-2">
                <Link to="/vendors/new">{t('vendors.create')}</Link>
              </Button>
            </>
          }
        >
          {t('vendors.title')}
        </Header>
        <Section>
          <Card spacing="none">
            <Table
              columns={columns}
              data={vendors}
              pagination={pagination}
              isLoading={isLoading}
              emptyText={t('vendors.empty')}
              onPageChange={(page) => setPage(page)}
              onRowClick={(vendor) => showVendor(vendor)}
            />
          </Card>
        </Section>
      </Page>
    </Checked>
  )
}

export default Vendors
