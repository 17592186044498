import { DocumentPlusIcon } from '@heroicons/react/24/outline'

import Button from '../core/Button/Button'

const UploadButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      type="button"
      variant="primary"
      size="lg"
      className="rounded-full p-4 shadow-md"
      onClick={onClick}
    >
      <DocumentPlusIcon aria-hidden="true" className="size-6" />
    </Button>
  )
}

export default UploadButton
