import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export interface ModalOptions {
  title?: string
}

interface ModalProps {
  options?: ModalOptions | null
  content?: ReactNode | null
  open: boolean
  setOpen: (open: boolean) => void
  onClosed?: () => void
}

export default function Modal({
  options,
  content,
  open = false,
  setOpen,
  onClosed,
}: ModalProps) {
  const { t } = useTranslation()

  const onClose = () => {
    setOpen(false)
  }

  const onTransitionEnd = () => {
    if (!open) {
      onClosed?.()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-50 w-full overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            onTransitionEnd={onTransitionEnd}
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 sm:my-8 inline-block sm:p-6 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div
              className={classnames('flex items-start', {
                'justify-between': options?.title,
                'justify-end': !options?.title,
              })}
            >
              {options?.title && (
                <DialogTitle className="text-lg font-semibold leading-6 text-gray-900">
                  {options.title}
                </DialogTitle>
              )}
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  onClick={onClose}
                  className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-black focus:ring-offset-2"
                >
                  <span className="absolute -inset-2.5" />
                  <span className="sr-only">{t('modal.close')}</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                </button>
              </div>
            </div>
            <div>{content}</div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}
