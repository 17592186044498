import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { loginUser } from '../../loaders/user'
import Button from '../core/Button/Button'
import Input from '../core/Input/Input'
import InputField from '../core/Input/InputField'

const LoginForm = () => {
  const { t } = useTranslation(['login'])
  const auth = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      try {
        const response = await loginUser({ email, password })

        auth.login(response, () => {
          const redirect = searchParams.get('redirect')
          if (redirect) {
            navigate(decodeURIComponent(redirect))
          } else {
            navigate('/')
          }
        })
      } catch (error) {
        toast.error(
          t('notification.error', {
            error: error instanceof Error ? error.message : 'Unknown error',
          })
        )
      }
    },
    [email, password, searchParams]
  )

  return (
    <form className="space-y-6" onSubmit={onSubmit}>
      <InputField id="email">
        <div>
          <div className="mt-1">
            <Input
              variant="solid"
              name="email"
              type="email"
              autoComplete="email"
              placeholder={t('form.email')}
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
        </div>
      </InputField>

      <InputField id="password">
        <div className="space-y-1">
          <div className="mt-1">
            <Input
              variant="solid"
              name="password"
              type="password"
              autoComplete="current-password"
              placeholder={t('form.password')}
              required
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
        </div>
      </InputField>

      <div>
        <Button variant="primary" type="submit" className="uppercase" isFull>
          {t('form.signIn')}
        </Button>
      </div>
    </form>
  )
}

export default LoginForm
