import { useMutation } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import ExternalPage from '../components/ExternalPage/ExternalPage'
import { SetPasswordForm } from '../components/SetPasswordForm/SetPasswordForm'
import { setPassword } from '../loaders/user'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { token } = useParams()
  if (!token) {
    navigate('/login')
  }

  const { mutate: setPasswordMutation } = useMutation({
    mutationFn: ({ password }: { password: string }) =>
      setPassword({ token: token!, password }),
    onSuccess: () => {
      toast.success(t('resetPassword.notification.success'))
      navigate('/login')
    },
    onError: (error: Error) => {
      toast.error(
        t('resetPassword.notification.error', { error: error.toString() })
      )
    },
  })

  return (
    <ExternalPage title={t('resetPassword.title')}>
      <SetPasswordForm
        onSubmit={(password) => setPasswordMutation({ password })}
      />
    </ExternalPage>
  )
}

export default ResetPassword
