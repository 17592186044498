import { ArrowRightIcon, SparklesIcon } from '@heroicons/react/24/outline'
import { ComponentPropsWithoutRef, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Reference } from '../../models'
import DisclosureBox from '../DisclosureBox/DisclosureBox'
import ProgressSteps from '../ProgressSteps/ProgressSteps'
import Button from '../core/Button/Button'
import Markdown from '../core/Markdown/Markdown'

const ChatBox = () => {
  const { t } = useTranslation(['dashboard'])

  return (
    <div className="h-12 bg-white p-2 rounded-full flex flex-row gap-2 items-center">
      <SparklesIcon className="size-4 text-gray-400 ml-2" />
      <input
        type="text"
        placeholder={t('ai.chat')}
        className="flex-1 block w-full p-0 text-sm text-gray-900 placeholder:text-gray-400 border-0 focus:ring-0"
      />
      <Button
        type="submit"
        variant="soft"
        size="xs"
        className="bg-brand-black h-full rounded-full"
      >
        <ArrowRightIcon className="size-4 text-white" />
      </Button>
    </div>
  )
}

export interface AISectionProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  text: string
  references?: Reference[]
  hrefFunc?: (reference: Reference) => string | undefined
}

export const AISection = ({
  title,
  text,
  references,
  hrefFunc,
}: AISectionProps) => {
  return (
    <div className="flex flex-col gap-2 p-3 bg-white text-gray-600 rounded-lg text-sm/6">
      <span className="font-semibold">{title}</span>
      <Markdown references={references} hrefFunc={hrefFunc}>
        {text}
      </Markdown>
    </div>
  )
}

export const AIDisclosureSection = ({ title, text }: AISectionProps) => {
  return (
    <DisclosureBox
      title={title}
      className="p-3 bg-white text-gray-600 rounded-lg text-sm"
    >
      <Markdown>{text}</Markdown>
    </DisclosureBox>
  )
}

const STEP_DURATION = 2000

const simulateSteps = async (
  length: number,
  initialStepIndex: number,
  setCurrentStepIndex: (index: number) => void
) => {
  const endStepIndex = length + 1 // Add an iteration to allow for the final step to animate
  for (let i = initialStepIndex; i <= endStepIndex; i++) {
    await new Promise((resolve) => setTimeout(resolve, STEP_DURATION))

    setCurrentStepIndex(i + 1)
  }
}

interface AISidebarProps extends ComponentPropsWithoutRef<'div'> {
  steps: string[]
  stepIndex?: number
  isLoading: boolean
  hasChat?: boolean
}

const AISidebar = ({
  steps,
  isLoading,
  stepIndex,
  children,
  hasChat = false,
}: AISidebarProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(stepIndex ?? 0)

  // Simulate steps visually
  useEffect(() => {
    setCurrentStepIndex(stepIndex ?? 0)
    simulateSteps(steps.length, currentStepIndex, setCurrentStepIndex)
  }, [stepIndex])

  // Track if all steps have been completed
  const stepsCompleted = useMemo(() => {
    return currentStepIndex >= steps.length
  }, [currentStepIndex, steps])

  return (
    <div className="bg-gray-100 w-96 h-full px-4 py-4 rounded-xl animate-fadeIn">
      {!stepsCompleted || isLoading ? (
        <div className="my-12">
          <ProgressSteps steps={steps} currentStepIndex={currentStepIndex} />
        </div>
      ) : (
        <div className="flex flex-col space-between h-full animate-fadeIn duration-300">
          <div className="grow">{children}</div>
          {hasChat && <ChatBox />}
        </div>
      )}
    </div>
  )
}

export default AISidebar
