import { Navigate } from 'react-router-dom'

import App from './components/App/App'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import RootErrorBoundary from './components/RootErrorBoundary'
import AccountCharts from './routes/AccountCharts'
import Admin from './routes/Admin'
import Apps from './routes/Apps'
import Buildings from './routes/Buildings'
import BusinessCentralApp from './routes/BusinessCentralApp'
import Confirm from './routes/Confirm'
import CreateAccountChart from './routes/CreateAccountChart'
import CreateBuilding from './routes/CreateBuilding'
import CreateNoiDefinition from './routes/CreateNoiDefinition'
import CreateRecord from './routes/CreateRecord'
import EditUser from './routes/EditUser'
import EditVendor from './routes/EditVendor'
import ForgotPassword from './routes/ForgotPassword'
import InternalBenchmarks from './routes/InternalBenchmarks'
import Invoice from './routes/Invoice/Invoice'
import Invoices from './routes/Invoices'
import Login from './routes/Login'
import Logout from './routes/Logout'
import Organization from './routes/Organization'
import Profile from './routes/Profile'
import Records from './routes/Records'
import ResetPassword from './routes/ResetPassword'
import Result from './routes/Result'
import Review from './routes/Review/Review'
import Setup from './routes/Setup'
import Tenants from './routes/Tenants'
import Users from './routes/Users'
import Vendors from './routes/Vendors'

const routes = [
  {
    path: '/login',
    element: <Login />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: '/confirm/:token',
    element: <Confirm />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: '/password/forgot',
    element: <ForgotPassword />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: '/password/reset/:token',
    element: <ResetPassword />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/',
    element: <ProtectedRoute />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        element: <App />,
        children: [
          {
            path: '/profile',
            element: <Profile />,
          },
          {
            path: '/admin',
            element: <Admin />,
            children: [
              {
                path: '/admin/apps',
                element: <Apps />,
              },
              {
                path: '/admin/users/:userId/edit',
                element: <EditUser />,
              },
              {
                path: '/admin/users/new',
                element: <EditUser />,
              },
              {
                path: '/admin/users',
                element: <Users />,
              },
              {
                path: '/admin/organization',
                element: <Organization />,
              },
            ],
          },
          {
            path: '/setup',
            element: <Setup />,
            children: [
              {
                path: '/setup/buildings/new',
                element: <CreateBuilding />,
              },
              {
                path: '/setup/buildings',
                element: <Buildings />,
              },
              {
                path: '/setup/account_charts/new',
                element: <CreateAccountChart />,
              },
              {
                path: '/setup/account_charts',
                element: <AccountCharts />,
              },
              {
                path: '/setup/noi_definitions/new',
                element: <CreateNoiDefinition />,
              },
              {
                path: '/setup/records/new',
                element: <CreateRecord />,
              },
              {
                path: '/setup/records',
                element: <Records />,
              },
              {
                path: '/setup/apps/businessCentral',
                element: <BusinessCentralApp />,
              },
              {
                path: '/setup/internal_benchmarks',
                element: <InternalBenchmarks />,
              },
              {
                path: '/setup',
                element: <Navigate to="/setup/buildings" replace />,
              },
            ],
          },
          {
            path: '/vendors',
            element: <Vendors />,
          },
          {
            path: '/vendors/invoices',
            element: <Invoices />,
          },
          {
            path: '/vendors/invoices/:invoiceId',
            element: <Invoice />,
          },
          {
            path: '/vendors/:vendorId/edit',
            element: <EditVendor />,
          },
          {
            path: '/vendors/new',
            element: <EditVendor />,
          },
          {
            path: '/tenants',
            element: <Tenants />,
          },
          {
            path: '/review',
            element: <Review />,
          },
          {
            path: '/',
            element: <Result />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]

export default routes
