import classNames from 'classnames'
import {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  ReactNode,
  forwardRef,
} from 'react'
import { twMerge } from 'tailwind-merge'

import * as Typography from '../core/Typography'

interface HeaderProps extends ComponentPropsWithoutRef<'header'> {
  actions?: ReactNode
  subtitle?: string
}

export const Header = ({ actions, subtitle, children }: HeaderProps) => {
  return (
    <header className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <Typography.H1>{children}</Typography.H1>
        {subtitle && <p className="mt-2 text-lg font-light">{subtitle}</p>}
      </div>
      {actions && <div className="mt-4 flex md:ml-4 md:mt-0">{actions}</div>}
    </header>
  )
}

export const Section = (props: ComponentPropsWithoutRef<'section'>) => (
  <section className="my-16" {...props} />
)

export const Title = ({ children }: ComponentPropsWithoutRef<'h3'>) => (
  <Typography.H3>{children}</Typography.H3>
)

export const Content = forwardRef<HTMLDivElement, ComponentPropsWithRef<'div'>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={classNames('my-6', className)} {...props} />
  )
)

Content.displayName = 'Content'

export const Page = ({
  children,
  className,
}: ComponentPropsWithoutRef<'main'>) => {
  return (
    <main
      className={twMerge(
        'min-w-0 mx-auto max-w-7xl flex-1 overflow-y-auto px-1',
        className
      )}
    >
      {children}
    </main>
  )
}
