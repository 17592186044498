import classnames from 'classnames'
import React, { ComponentPropsWithoutRef } from 'react'
import { twMerge } from 'tailwind-merge'

const styles = {
  variant: {
    primary:
      ' bg-brand-black text-white hover:bg-brand-gray-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white',
    neutral:
      'bg-white text-gray-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
    soft: 'bg-brand-neutral-1 text-brand-gray-dark hover:bg-brand-neutral-2',
    transparent: 'text-brand-gray-dark hover:bg-brand-neutral-1',
    negative: 'bg-red-100 text-red-600 hover:bg-red-50',
  },
  size: {
    xs: 'rounded px-2 py-1 text-xs font-medium',
    sm: 'rounded px-2 py-1 text-sm font-medium',
    md: 'rounded-lg px-2.5 py-1.5 text-sm font-medium',
    lg: 'rounded-lg px-3 py-2 text-sm font-medium',
    xl: 'rounded-lg px-3.5 py-2.5 text-sm font-medium',
  },
}

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  as?: React.ElementType
  isFull?: boolean
  children: React.ReactNode
  variant?: 'primary' | 'negative' | 'neutral' | 'soft' | 'transparent'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  className?: string
}

export const Button = ({
  as: Component = 'button',
  isFull,
  children,
  variant = 'neutral',
  size = 'lg',
  className,
  ...props
}: ButtonProps) => {
  return (
    <Component
      type="button"
      className={twMerge(
        classnames(
          'inline-flex items-center',
          styles.variant[variant],
          styles.size[size],
          {
            'w-full justify-center': isFull,
          },
          {
            'disabled:opacity-50': props.disabled,
          }
        ),
        className
      )}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Button
